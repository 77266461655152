.sheet-manager-button {
	display: flex;
	background-color: #e6e6e6;
	width: 140px;
	padding-left: 13px !important;
	padding-right: 13px !important;
}

.sheet-manager-arrow {
	float: right;
	vertical-align: middle;
}

#sheet-manager-table-container {
	position: fixed;
	background-color: #e6e6e6;
	font-size: 13px;
	z-index: 1031;
	padding: 20px;
	max-height: 400px;
	margin-top: 30px;
}

#sheet-manager-table {
	max-height: 360px;
	overflow-y: auto;
	display: block;
}

#sheet-manager-table td {
	padding: 3px 0px 3px 0px;
}

#sheet-manager-table tr td:nth-child(2) {
	display: flex;
	width: auto;
}
#sheet-manager-table tr td:nth-child(3) {
	display: flex;
	width: auto;
}
#sheet-manager-table tr td:first-child,
#sheet-manager-table th:first-child {
	min-width: 300px !important;
}

#sheet-manager-table th {
	padding: 0px 10px 3px 10px;
}

#sheet-manager-table th:first-child {
	padding: 0px 20px 3px 20px;
	border-bottom: 1px solid var(--webl_black);
	border-right: 1px solid var(--webl_black);
}

#sheet-manager-table th:nth-child(2) {
	padding: 0px 10px 3px 10px;
	position: relative;
	width: 96px;
}

#sheet-manager-table th:nth-child(3) {
	padding: 0px 10px 3px 10px;
	position: relative;
	width: 96px;
}

#sheet-manager-table tr td:nth-child(2) {
	gap: 10px;
	margin: 0px 20px;
}
#sheet-manager-table tr td:nth-child(3) {
	gap: 10px;
	margin: 0px 20px;
}

.table-row {
	box-shadow: -20px 1px 0 0 #cacacb;
	display: flex;
	padding: 5px 0px;
	align-items: center;
}

.dropdown-icon-button {
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-color: transparent;
	outline: 2px solid transparent;
}

.dropdown-icon-button:active {
	background-color: #d3d3d3;
	outline: 2px solid #d3d3d3;
}

.sheet-number {
	font-size: 16px;
}

.sheet-name {
	margin-left: 8px;
}

#actions-span {
	padding-left: 8px;
	padding-bottom: 3px;
	width: 100%;
}

#actions-span::after {
	content: "";
	position: absolute;
	left: 10px;
	bottom: 0;
	width: calc(100% - 10px);
	height: 0.7px;
	background-color: var(--webl_black);
}

#table-header {
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: #e6e6e6;
}

#webl-creation-container .rc-scrollbars-track-h {
	display: none;
}

#webl-creation-container .rc-scrollbars-track-v {
	right: 0px !important;
	background-color: white;
}
